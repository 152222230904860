<template>
  <el-dialog
    :title="$t('updatePassword.title') "
    :visible.sync="visible"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :append-to-body="true"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item :label="$t('updatePassword.username')">
        <span>{{ userName }}</span>
      </el-form-item>
      <el-form-item :label="$t('updatePassword.password')" prop="password">
        <el-input type="password" v-model="dataForm.password"></el-input>
      </el-form-item>
      <el-form-item :label="$t('updatePassword.newPassword')" prop="newPassword">
        <el-input type="password" v-model="dataForm.newPassword"></el-input>
      </el-form-item>
      <el-form-item :label="$t('updatePassword.comfirmPassword')" prop="confirmPassword">
        <el-input type="password" v-model="dataForm.confirmPassword"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()">{{ $t('confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { clearLoginInfo } from "@/utils";
import { encryptDes } from "@/utils/des";
import { validatorPassword } from '@/utils/validate'
import {updatePassword} from '@/api/sys/user'
export default {
  data() {
    var validatePass = (rule , value , callback)=>{
      if(!validatorPassword(value)) {
        callback(new Error('密码要求长度8~20位,并且不能为纯数字或纯字母!'))
      } else {
        callback()
      }
    }
    var validateConfirmPassword = (rule, value, callback) => {
      if (this.dataForm.newPassword !== value) {
        callback(new Error(this.$t('updatePassword.validate.comfirmPassword')));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataForm: {
        password: "",
        newPassword: "",
        confirmPassword: ""
      },
      dataRule: {
        password: [
          { required: true, message: this.$t('validate.required'), trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' },
          { validator: validateConfirmPassword, trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    userName: {
      get() {
        return this.$store.state.user.name;
      }
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs;
      },
      set(val) {
        this.$store.commit("common/updateMainTabs", val);
      }
    }
  },
  methods: {
    // 初始化
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          //密码加密
          const o_pass = encryptDes(this.dataForm.password);
          const n_pass = encryptDes(this.dataForm.newPassword); 
         
          updatePassword(o_pass,n_pass).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('prompt.success'),
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$nextTick(() => {
                    this.mainTabs = [];
                    clearLoginInfo();
                    this.$router.replace({ name: "login" });
                  });
                }
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    }
  }
};
</script>
