<template>
  <div>
    <el-dialog
      :title="$t('theme.title')"
      :append-to-body="true"
      :visible.sync="box"
      :modal-append-to-body="true"
      :modal="true"
      width="40%"
    >
      <el-form ref="form" label-width="100px"> 
        <el-form-item :label="$t('theme.layout')">
          <el-radio-group v-model="layout" class="list">
            <el-radio-button :label="false">{{ $t('disable') }}</el-radio-button>
            <el-radio-button :label="true">{{ $t('enable') }}</el-radio-button>
          </el-radio-group>
        </el-form-item> 
        <el-form-item :label="$t('theme.openTabs')">
          <el-radio-group v-model="openTabs" class="list">
            <el-radio-button :label="false">{{ $t('disable') }}</el-radio-button>
            <el-radio-button :label="true">{{ $t('enable') }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('theme.openBreadcrumb')">
          <el-radio-group v-model="openBreadcrumb" class="list">
            <el-radio-button :label="false">{{ $t('disable') }}</el-radio-button>
            <el-radio-button :label="true">{{ $t('enable') }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('theme.breadcrumbLayout')" v-if="openBreadcrumb">
          <el-radio-group v-model="breadcrumbLayout" class="list">
            <el-radio-button label="header">{{ $t('theme.header') }}</el-radio-button>
            <el-radio-button label="content">{{ $t('theme.content') }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="box = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="setTheme()" :disabled="isOnClick"
          >{{ $t('confirm') }}</el-button
        >
      </span>
    </el-dialog>

    <span :title="$t('theme.title')">
      <i class="el-icon-setting pointer" @click="open"></i>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      box: false,
      text: "",
      isOnClick: false,
      list: [
        {
          name: "默认主题",
          value: "defaluttheme"
        },
        {
          name: "炫彩主题",
          value: "startheme"
        },
        {
          name: "渐变主题",
          value: "gradualstheme"
        }
      ],
      layout: false,

      openBreadcrumb: true,
      breadcrumbLayout: "header",
      openTabs: false
    };
  },
  computed: {
    // ...mapGetters(["themeName"])
    menuLayout: {
      set(val) {
        this.$store.commit("common/updateMenuLayout", val);
      },
      get() {
        return this.$store.state.common.menuLayout;
      }
    },
    breLayout: {
      set(val) {
        this.$store.commit("common/updateBreadcrumbLayout", val);
      },
      get() {
        return this.$store.state.common.breadcrumbLayout;
      }
    },
    breOpen: {
      set(val) {
        this.$store.commit("common/updateBreadcrumbOpen", val);
      },
      get() {
        return this.$store.state.common.breadcrumbOpen;
      }
    },
    tabOpen: {
      set(val) {
        this.$store.commit("common/updateTabOpen", val);
      },
      get() {
        return this.$store.state.common.tabOpen;
      }
    }
  },
  mounted() {},
  methods: {
    open() {
      this.text = localStorage.getItem("theme_color");
      this.layout = this.menuLayout

      this.openBreadcrumb = this.breOpen;
      this.breadcrumbLayout = this.breLayout;
      this.openTabs = this.tabOpen;


      // if (!this.layout) {
      //   this.layout = "sidebar";
      // }

      if (!this.text) {
        var cs = document.body.className;
        if (cs) {
          var csarray = cs.split(" ");
          for (var i = 0; i < csarray.length; i++) {
            if (csarray[i] && csarray[i].indexOf("theme") >= 0) {
              this.text = csarray[i];
              break;
            }
          }
        }
      }

      this.box = true;
    },
    setTheme(name) {
      this.isOnClick = true;

      // var classNames = this.text + " " + ("tr-" + this.layout);


      // document.body.className = classNames;
      localStorage.setItem("theme_color", this.text);
      localStorage.setItem("menu_layout", this.layout);
      localStorage.setItem("openBreadcrumb", this.openBreadcrumb);
      localStorage.setItem("breadcrumbLayout", this.breadcrumbLayout);
      localStorage.setItem("openTabs", this.openTabs);
      

      this.menuLayout = this.layout;
      this.breLayout = this.breadcrumbLayout;
      this.breOpen = this.openBreadcrumb;
      this.tabOpen = this.openTabs;

      this.isOnClick = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  width: 100%;
}
</style>
