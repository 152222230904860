<template>
  <nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">

    <div class="site-navbar__header" :class="'site-navbar-' + breLayout" v-if="!sidebarFold">
      <h1 class="site-navbar__brand" @click="$router.push({ name: 'home' })">
        <a class="site-navbar__brand-lg" href="javascript:;">{{value}}</a>
        <!--  <a class="site-navbar__brand-mini" href="javascri pt:;">{{little_title}}</a>   -->
      </h1>
    </div>

    <div class="gzt" v-if="larscreen!=null&&larscreen.length>0">
      <el-button type="primary" plain @click.native="gzt()" >可 视 化</el-button>
    </div>

    <div class="site-navbar__body clearfix site-navbar-sidebar" style="height: 50px;">

      <div class="logout">
        <span :title="$t('logout')" @click="logoutHandle()"></span>
      </div>

      <theme class="top-theme"></theme>

      <el-menu class="site-navbar__menu site-navbar__menu--right" mode="horizontal">
        <el-menu-item class="site-navbar__avatar" index="3">

          <el-dropdown :show-timeout="0" placement="bottom" size="small">
            <span class="el-dropdown-link username" @click="userHandle()">
              <!-- <img src="~@/assets/img/avatar.png" :alt="userName"> -->
              {{ userName }}<i style="font-size: 16px" class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item divided icon="el-icon-user" @click.native="userHandle()">{{ $t('personalinformation') }}</el-dropdown-item>
              <el-dropdown-item divided icon="el-icon-key" @click.native="updatePasswordHandle()">{{ $t('updatePassword.title') }}</el-dropdown-item>
              <el-dropdown-item divided icon="el-icon-remove-outline" @click.native="logoutHandle()">{{ $t('logout') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
      </el-menu>

      <el-menu
        v-if="menuLayout"
        :default-active="activeNavbar"
        class="tr-navbar-menu"
        mode="horizontal"
        @select="handleMenuSelect">
        <el-menu-item :index="menu.menuId" v-for="menu in menuList" :key="menu.menuId">
          <span>{{ menu.name }}</span>
        </el-menu-item>
      </el-menu>

      <el-menu v-if="breLayout == 'header'" class="site-navbar__menu" :class="{'site-navbar-fold': sidebarFold}" mode="horizontal">
        <el-menu-item class="site-navbar__switch " index="0" @click="sidebarFold = !sidebarFold">
          <hamburger :is-active="!sidebarFold" class="zhedie_transform" color="white" />
          <!--  <icon-svg name="zhedie" class="zhedie_transform" style="color:white"></icon-svg> -->
        </el-menu-item>
      </el-menu>

      <breadcrumb v-if="breOpen && breLayout == 'header'"></breadcrumb>

    </div>

    <!-- 弹窗, 修改密码 -->
    <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>
  </nav>
</template>

<script>
import { messages } from '@/i18n'
import UpdatePassword from "./main-navbar-update-password";
import { clearLoginInfo } from "@/utils";
import theme from "@/components/top/theme";
import breadcrumb from "@/components/breadcrumb";
// import msgCenter from "@/components/msg/index";
import hamburger from '@/components/hamburger'
import { listByUserid } from '@/api/hbi/project/bscreen.js';
import { logout } from "@/api/sys/user";
import { getValue } from '@/api/sys/config'
export default {
  data() {
    return {
      i18nMessages: messages,
      showColor: false,
      updatePassowrdVisible: false,
      msgCenterVisible: false,

      activeIndex: '1',
      full_title: '',
      little_title: "",
      value:'',
      //待处理的消息数量
      msg_count: 0,
      larscreen: []

    };
  },
  components: {
    UpdatePassword,
    // msgCenter,
    breadcrumb,
    theme,
    hamburger
  },
  computed: {
    navbarLayoutType: {
      get() {
        return this.$store.state.common.navbarLayoutType;
      }
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
      set(val) {
        this.$store.commit("common/updateSidebarFold", val);
      }
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs;
      },
      set(val) {
        this.$store.commit("common/updateMainTabs", val);
      }
    },
    userName: {
      get() {
        return this.$store.state.user.name;
      }
    },
    sidebarLayoutSkin: {
      get() {
        return this.$store.state.common.sidebarLayoutSkin;
      }
    },
    menuLayout: {
      get() {
        return this.$store.state.common.menuLayout;
      }
    },
    breLayout: {
      get() {
        return this.$store.state.common.breadcrumbLayout;
      }
    },
    menuList: {
      get() {
        return this.$store.state.common.menuList;
      }
    },
    activeNavbar: {
      get() {
        return this.$store.state.common.activeNavbar;
      },
      set(val) {
        this.$store.commit("common/updateActiveNavbar", val);
      }
    },
    breOpen: {
      get() {
        return this.$store.state.common.breadcrumbOpen;
      }
    },

    total_cnt: {
      get() {
        return this.$store.state.message.total_cnt;
      },
      set(val) {
        this.$store.commit("message/updateTotalCnt", val);
      }
    },

    wait_cnt: {
      get() {
        return this.$store.state.message.wait_cnt;
      },
      set(val) {
        this.$store.commit("message/updateWaitCnt", val);
      }
    },

    job_cnt: {
      get() {
        return this.$store.state.message.job_cnt;
      },
      set(val) {
        this.$store.commit("message/updateJobCnt", val);
      }
    },

    sys_cnt: {
      get() {
        return this.$store.state.message.sys_cnt;
      },
      set(val) {
        this.$store.commit("message/updateSysCnt", val);
      }
    },
  },
  watch: {
    menuList(nval, oval) {
      if (!this.activeNavbar && nval && nval.length > 0) {
        this.activeNavbar = nval[0].menuId
      }
    }
  },
  created() {

    // 初始化判断当前菜单目录的选中值 如果没有 则使用第一个
    //this.menuList = JSON.parse(sessionStorage.getItem("menuList") || "[]");
    // let ms = this.menuList
    // if(!ms) {
    //   ms = JSON.parse(sessionStorage.getItem("menuList") || "[]");
    // }

    // console.log('this.activeNavbar' , this.activeNavbar , ms)

    // if(!this.activeNavbar && ms && ms.length > 0) {
    //   this.activeNavbar = ms[0].menuId
    // }
    this.getKey()
  },
  mounted() {
    this.getbscreens()

  },
  methods: {

    clickLanguage(key) {
      this.$i18n.locale = key

    },
    // 修改密码
    updatePasswordHandle() {
      this.updatePassowrdVisible = true;
      this.$nextTick(() => {
        this.$refs.updatePassowrd.init();
      });
    },
    getbscreens() {
      listByUserid().then(({ data }) => {
        if (data && data.code == 0) {
          this.larscreen = data.data
        } else if (data && data.msg) {
          this.$message.error(data.msg)
        }
      }).finally(() => {
        this.categoryLoading = false
      })
    },
    gzt() {
      let id = "";
      if (this.larscreen != null && this.larscreen.length > 0) {
        id = this.larscreen[0].bscreenId
      } else {
        this.$message.error("该用户未配置可视化！")
        return
      }

      const query = { id: id }

      if (this.$store.state.user.regionCode != '1000') {
        query.adCode = this.$store.state.user.adcode
      }

      console.log('查询参数: ',query,this.$store.state.user.adcode)

      this.$router.push({
        path: '/stat',
        query
      })

    },
    userHandle() {
      this.$router.push({ name: 'userinfo' })
    },
    handleMenuSelect(select) {
      this.activeNavbar = select
    },
    logoutoa(ticket) {
      const query = {
        ticket: ticket,
      }
      logout(query).then((data) => {
        this.$cookie.set("ticket", '');
        if (data && data.data.code == 0) {
          var userAgent = navigator.userAgent;
          if (userAgent.indexOf("MSIE") > 0) {
            if (userAgent.indexOf("MSIE 6.0") > 0) {
              window.opener = null;
              window.close();
            } else {
              window.open("", "_top");
              window.top.close();
            }
          } else if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
            window.location.href = "about:blank"; //火狐默认状态非window.open的页面window.close是无效的
            window.close();
            //window.history.go(-2);
          } else {
            window.opener = null;
            window.location.href = "about:blank";
            window.close();
          }
        } else {
          this.error = true
        }

      })
    },

    //配置首页的名称
    getKey() {
      const key = 'name'
      //debugger
      getValue(key).then(({ data }) => {
        if (data && data.code == 0) {
          this.value = data.data
          console.log(this.value)
        } else if (data && data.msg) {
          this.$message.error(data.msg)
        }
      }).catch((e) => {})
    },


    // 退出
    logoutHandle() {
      let ticket = this.$cookie.get("ticket");
      if (!ticket) {
        this.$confirm(`您确定要退出此系统?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          clearLoginInfo()
          this.$router.push({ name: 'login' })
        }).catch(() => {})
      } else {
        this.$confirm(`您确定要退出此系统,并关闭窗口?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          clearLoginInfo()
          this.logoutoa(ticket);
        }).catch(() => {})
      }

    },
  }
};
</script>

<style lang="scss">
.top-theme {
  line-height: 45px;
  height: 45px;
  float: right;
  width: 30px;
}

.site-navbar-sidebar {
  margin-left: 1px !important;
}

.site-navbar-fold {
  .el-menu-item {
    width: 64px;
    text-align: center;
  }
}

.site-navbar {
  .el-menu-item {
    padding: 0 10px;
  }

  .tr-navbar-menu {
    float: right;
    /*  margin-right: 100px; */
    background-color: transparent;

    .el-menu-item {
      min-width: 80px;
      text-align: center;
    }
  }
}

.site-navbar__avatar {

  .username {
    font-size: 13px;
  }

  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

}

.logout {
  line-height: 45px;
  height: 45px;
  float: right;
  /* width: 50px; */
  /* vertical-align: middle; */
  /* margin: auto; */
  display: inline-flex;

  span {
    background: url(~@/assets/img/logout.svg) no-repeat 0;
    height: 32px;
    float: right;
    width: 32px;
    vertical-align: middle;
    margin: auto;
    line-height: 45px;
    cursor: pointer;
  }

}

.down-url {
  float: right;
  padding-right: 20px;
  line-height: 45px;
}

.tr-tooltips {
  margin-right: 10px;
}

.gzt {
  line-height: 50px;
  height: 100%;
  float: right;
  width: 50px;
  display: inline-flex;
  margin: 0 20px;
  justify-content: center;
  align-items: center;

  .el-button{
    height: 65%;
  }

}
</style>
